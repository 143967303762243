import { PaletteOptions, SimplePaletteColorOptions, TypeText } from '@mui/material';


interface ExtendedPaletteColorOptions extends SimplePaletteColorOptions
{
	lighter?: string
	darker?: string
}


interface ExtendedPaletteOptions extends PaletteOptions
{
	primary: ExtendedPaletteColorOptions
	secondary: ExtendedPaletteColorOptions
	text?: Partial<TypeText>
	error?: ExtendedPaletteColorOptions
	warning?: ExtendedPaletteColorOptions
	info?: ExtendedPaletteColorOptions
	success?: ExtendedPaletteColorOptions
	// And your custom palette options if you defined them, e.g:
	// brand: ExtendedPaletteColorOptions
}


export const palette: ExtendedPaletteOptions =
{
	primary: {
		lighter: '#9fa8da',
		light: '#3949ab',
		main: '#18227c',
		dark: '#00004f',
		contrastText: '#fff'
	},
	secondary: {
		lighter: '#ffb74d',
		light: '#ff7961',
		main: '#f98b00',
		dark: '#9f0000',
		darker: '#870000',
		contrastText: '#000'
	}
	// text,
	// error,
	// warning,
	// info,
	// success,
};
