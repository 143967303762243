


export const firebase_config = () => {
	let config;

	if (process.env.REACT_APP_ENV === 'production') {
		// config for deployed production env
		config =
		{
			apiKey: 'AIzaSyDt3ZDxixszFHW6zX5Z6R96MZhuZ1eN2no',
			authDomain: 'rex-rxfire.firebaseapp.com',
			projectId: 'rex-rxfire',
			storageBucket: 'rex-rxfire.appspot.com',
			messagingSenderId: '619009796516',
			appId: '1:619009796516:web:4554fd472e1d2cf7aef931'
		};
	} else if (process.env.REACT_APP_ENV === 'staging') {
		// config for deployed staging env
		config =
		{
			apiKey: 'AIzaSyCzeN2gRsuby0xSZPkahnO1EwGtfMFLehU',
			authDomain: 'farside-319118.firebaseapp.com',
			projectId: 'farside-319118',
			storageBucket: 'farside-319118.appspot.com',
			messagingSenderId: '302253752128',
			appId: '1:302253752128:web:cd13f481e41b82c50ac4a5',
			measurementId: 'G-1MJ8GP98H5'
		};
	} else {
		// Both hosted_dev and local dev use the same farside-319118 project
		config =
		{
			apiKey: 'AIzaSyC8Y5T0mtM6UcqwfENA2RB3JLxDt4_rcZM',
			authDomain: 'poly-dev-cmsd.firebaseapp.com',
			projectId: 'poly-dev-cmsd',
			storageBucket: 'poly-dev-cmsd.appspot.com',
			messagingSenderId: '319179996502',
			appId: '1:319179996502:web:bb0d103f8d21830788eaf7'
		};
	}

	return config;
};

export default firebase_config;
